import logoBradesco from "./assets/img/bradesco_seguros_logo.jpeg"
import logoSicred from "./assets/img/sicred_logo.png"
import logoAwnet from "./assets/img/awnet_logo.png"
import logoTribeca from "./assets/img/tribeca_logo.jpeg"
import logoAmbipar from "./assets/img/ambipar_logo.png"
import logoBraveo from "./assets/img/braveo_logo.png"
import logoPolleto from "./assets/img/polleto_logo.svg"
import logoC6 from "./assets/img/c6_logo.png"

const clients = {
    BRADESCO_SEGUROS: {
        logoImg: logoBradesco,
        label: "Bradesco seguros",
        apiUrl: process.env[`REACT_APP_API_URL`],
        externalLink: "https://www.bradescoseguros.com.br/",
        enabledSearch: ['phone', 'cpf_cnpj'],
        searchTypes: ['preventivo'],
    },
    SICREDI: {
        logoImg: logoSicred,
        label: "Graça advogados",
        apiUrl: process.env[`REACT_APP_API_URL`],
        externalLink: "https://www.graca.adv.br/",
        enabledSearch: ['cpf_cnpj'],
        searchTypes: ['preventivo'],
    },
    AWNET: {
        logoImg: logoAwnet,
        label: "Athié Wohnrath",
        apiUrl: process.env[`REACT_APP_API_URL`],
        externalLink: "https://www.athiewohnrath.com.br/",
        enabledSearch: ['cpf_cnpj'],
        searchTypes: ['preventivo'],
    },
    AMBIPAR: {
        logoImg: logoAmbipar,
        label: "Ambipar",
        apiUrl: process.env[`REACT_APP_API_URL`],
        externalLink: "https://ambipar.com/",
        enabledSearch: ['cpf_cnpj'],
        searchTypes: ['preventivo'],
    },
    TRIBECA: {
        logoImg: logoTribeca,
        label: "Tribeca",
        apiUrl: process.env[`REACT_APP_API_URL`],
        externalLink: "https://tribecafinancas.com.br/",
        enabledSearch: ['cpf_cnpj'],
        searchTypes: ['preventivo'],
    },
    BRAVEO: {
        logoImg: logoBraveo,
        label: "Braveo",
        apiUrl: process.env[`REACT_APP_API_URL`],
        externalLink: "https://braveo.com.br/",
        enabledSearch: ['cpf_cnpj'],
        searchTypes: ['preventivo'],
    },
    POLLETO: {
        logoImg: logoPolleto,
        label: "Polleto & Possamai",
        apiUrl: process.env[`REACT_APP_API_URL`],
        externalLink: "https://polleto.com.br/",
        enabledSearch: ['cpf_cnpj'],
        searchTypes: ['preventivo', 'simples'],
    },
    C6: {
        logoImg: logoC6,
        label: "C6 Bank",
        apiUrl: process.env[`REACT_APP_API_URL`],
        externalLink: "https://www.c6bank.com.br/",
        enabledSearch: ['phone', 'cpf_cnpj'],
        searchTypes: ['custom', "custom-veiculos", 'preventivo', 'simples'],
    }
}

export const client = clients[process.env.REACT_APP_CLIENT_NAME]